import * as React from 'react';
import Header from './header';
import Footer from './footer';
import styles from './layout.module.scss';

interface LayoutProps {
  location: Location,
  children: React.ReactNode
}

export default class Layout extends React.Component<LayoutProps> {

  public render() {
    const children = this.props.children;
    return (
      <div id="top" className={`application ${styles.mainBackground}`}>
        <Header />
        <div>
          {children}
        </div>
        <Footer location={this.props.location} />
      </div>
    );
  }
}
