import { Link } from 'gatsby';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faLocationArrow, faMapPin } from '@fortawesome/free-solid-svg-icons'
import styles from './events-list.module.scss';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import moment from 'moment';
import 'moment/locale/nl';

import axios from 'axios';
import { useEffect, useState } from 'react';

export interface AgendaNode {
  id: string
  Soort: string,
  TypeOptreden: string[],
  Onderdeel: string,
  Title: string,
  Beschrijving: string,
  Starttijd: string,
  Eindtijd: Date,
  Locatie: string,
  Adres: string,
  Plaats: string,
  Land: string;
  social: string,
  tickets: string,
  sdayofmonth: string,
  sdate: string,
  syear: string
  smonth_short: string,
  smonth: string,
  sday: string,
  stime: string,
  edate: string,
  edayofmonth: string,
  emonth: string,
      etime: string,
}

interface EventListProps {
  limit?: number,
  eventTypes?: string[]
}

const EventsList: React.SFC<EventListProps> = ({
  limit,
  eventTypes = ['Optreden', 'Activiteit']
}) => {
  const startOfToday = moment().startOf('day').toDate();
  
  const [ agendaItems, setAgendaItems ] = useState([]);

  moment.locale('nl');

  useEffect(() => {
    const verenigingskalenderJson = 'https://static.jubal.org/data/verenigingskalender.json';
    axios.get(verenigingskalenderJson, {
    headers: { 'Access-Control-Allow-Origin':'*', 'Content-Type':'application/json' }
    })
    .then((response) => {
        // console.log(response);
        const items = response.data.value
          .map((item: any) => ({
            Soort: item.Soort.Value,
            TypeOptreden: item?.Typeoptreden.map((x: { Value: string}) => x.Value),
            Onderdeel: item.Onderdeel?.Value,
            Title: item.Title,
            Beschrijving: item.Beschrijving,
            Starttijd: item.Datum,
            Eindtijd: item.OData__EndDate,
            Locatie: item.Locatie1,
            Adres: item.Adres,
            Plaats: item.Plaats,
            Land: item.Land_x002f_regio,
            social: item.Verwijzingsocialmedia,
            tickets: item.Organisatie_x002f_tickets
          }));
        setAgendaItems(items);
        // console.log(agendaItems);
    });
  }, []);

  const upcomingEvents = agendaItems.map((x:AgendaNode) => x)
    .filter(x => eventTypes.indexOf(x.Soort) >= 0)
    .filter(x => x.Eindtijd && new Date(x.Eindtijd).getTime() >= startOfToday.getTime())
    .sort((x, y) => new Date(x.Starttijd).getTime() - new Date(y.Starttijd).getTime());
  const upcomingEventsList = upcomingEvents.filter((_, index) => !limit || index < limit);
  const upcomingEventsHeaderText = upcomingEventsList.length > 0 ? 'Aankomende' : 'Geen aankomende';
  return (
    <div>
      <h4 className="text-center text-muted no-print">
          <span>{upcomingEventsHeaderText}&nbsp;<Link to={`/agenda`} activeClassName="font-weight-bold">evenementen</Link> | <Link to={`/repetities`} activeClassName="font-weight-bold">repetities</Link>.</span>
      </h4>
      <p className="mb-5 text-center text-muted no-print">
        <small>Deze informatie is onderhevig aan wijzigingen. Tijden dienen enkel ter indicatie.</small>
      </p>
      {upcomingEventsList.map((data, index) => {
        const stime = moment(data.Starttijd);
        const etime = moment(data.Eindtijd);
        data.sdate = stime.format('DD MMMM YYYY');
        data.sdayofmonth = stime.format('DD');
        data.sday = stime.format('dddd');
        data.smonth = stime.format('MMMM');
        data.smonth_short = stime.format('MMM');
        data.syear = stime.format('YYYY');
        data.stime = stime.format('HH:mm');
        data.edate = etime.format('DD MMMM YYYY');
        data.edayofmonth = etime.format('DD');
        data.emonth = etime.format('MMMM');
        data.etime = etime.format('HH:mm');

        const unit = getVerenigingsonderdeelLabel(data.Onderdeel);
        const startDate = data.Starttijd ? moment(data.Starttijd).format('yyyy-MM-DD') : '';
        const endDate = data.Eindtijd ? moment(data.Eindtijd).format('yyyy-MM-DD') : '';
        const startTime = data.Starttijd ? moment(data.Starttijd).format('HH:mm') : '';
        const endTime = data.Eindtijd ? moment(data.Eindtijd).format('HH:mm') : '';
        const endTimeDateSuffix = data.edate && data.edate !== data.sdate && endTime !== '00:00' ? `tot ${data.edayofmonth} ${data.emonth}` : '-';
        const ignorableCountryValues = ['Nederland', null];
        const landSuffix = !data.Land || ignorableCountryValues.indexOf(data.Land) > -1 ? '' : `, ${data.Land}`;
        const eventId = `event${index}`;
        const locationArray = [data.Locatie, data.Adres, data.Plaats, data.Land === 'Nederland' ? '' : (data.Land || '')];
        const isRehearsal = data.Soort === 'Repetitie';
        const isDescriptionWrapped = data.Beschrijving?.length > 165 && limit !== undefined;
        const descriptionDisplayValue = isDescriptionWrapped
          ? `${data.Beschrijving?.substring(0, 150 + data.Beschrijving.substring(150).indexOf(' '))}...`
          : data.Beschrijving;

        return (
          <div key={eventId} className={`row ${styles.rowStriped} ${styles.rowHover} d-flex flex-nowrap deco-none print-do-not-break`}>
            <div id={eventId} className={`px-3 px-lg-4 px-xl-5 text-center`}>
              <div className={`float-right no-print ${styles.calendarButton}`}>
                <AddToCalendarButton
                  options={['Google','iCal','Outlook.com','Microsoft365','Apple']}
                  name={`[${data.Onderdeel}] ${data.Title}`}
                  description={`Kijk voor de laatste details op https://jubal.org/${isRehearsal ? 'repetities' : 'agenda'}${data.social ? ` of meld je aan voor het Facebook evenement (${data.social.Url})` : ''}.`}
                  location={locationArray.join(' ')}
                  startDate={startDate}
                  endDate={endDate}
                  startTime={startTime}
                  endTime={endTime}
                  size="1"
                  trigger="click"
                  listStyle='dropdown'
                  hideTextLabelButton
                  hideBranding
                  timeZone="Europe/Amsterdam"
                  language="nl"></AddToCalendarButton>
              </div>
              <h2 className="display-4"><span className={`badge badge-secondary ${styles.dateBadge}`}>{data.sdayofmonth}</span></h2>
              <h2>{data.smonth_short?.replace('.','').toUpperCase()}</h2>
              <h3 className="text-primary">{data.syear}</h3>
            </div>
            <div className="flex-grow-1 px-3 text-left">
              <AnchorLink to={`/agenda#${eventId}`} key={eventId} stripHash={true} className="deco-none">
                <h3 className="mb-0">
                  <strong>{data.Title}</strong>
                </h3>
              </AnchorLink>
              <h5 className="my-0 text-uppercase text-muted font-weight-light">{unit}</h5>
              <ul className="list-inline text-uppercase">
                {data.TypeOptreden?.map(typeOptreden => (
                  <li key={eventId + 'typeOptreden' + typeOptreden} className="list-inline-item badge badge-secondary">{typeOptreden?.toUpperCase()}</li>
                ))}
              </ul>
              <ul className={`list-inline mt-3 ${styles.printTextSizing}`}>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                  <span>{`${data.sday?.substring(0,1).toUpperCase()}${data.sday?.substring(1)}`} {data.sdayofmonth} {data.smonth}</span>
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faClock} className="mr-2" />
                  {startTime === '00:00' ? '' : <span>{startTime}</span>}
                  {!data.etime || data.etime === '00:00' ? '' : <span> {endTimeDateSuffix} {endTime}</span>}
                </li>
                {data.Plaats ? (
                  <li className="list-inline-item">
                    <FontAwesomeIcon icon={faLocationArrow} className="mr-2" />
                    <span>{data.Plaats}{landSuffix}</span>
                  </li>
                ) : (null) }
                {!!(data.Locatie || data.Adres) ? (
                  <li className="list-inline-item">
                    <FontAwesomeIcon icon={faMapPin} className="mr-2" />
                    <a className="deco-none" target="_blank" href={`https://www.google.nl/maps/search/${locationArray.filter(x => !!x).join('%20')}`}>{data.Locatie || data.Adres}</a>
                  </li>
                ) : (null) }
              </ul>
              <p className={`text-muted ${styles.breakspaces} ${styles.printDescription} ${styles.printTextSizing}`}>
                {descriptionDisplayValue}{descriptionDisplayValue ? <span>&nbsp;</span> : (null)}
                <AnchorLink className={`${(isDescriptionWrapped ? '' : 'd-none')}`} to={`/agenda#${eventId}`} key={eventId} stripHash={true}>
                  <span>lees verder →</span>
                </AnchorLink>
              </p>
              <div className="row no-gutters no-print">
                { data.social && limit === undefined ? (
                  <Link to={data.social || ""} target="_blank" className="col-12 col-md-6 pl-0 pb-2">
                    <span className="font-italic">Bekijk het evenement op social media →</span>
                  </Link>) : (<span></span>)
                }
                { data.tickets && limit === undefined ? (
                  <Link to={data.tickets || ""} target="_blank" className="col-12 col-md-6 pl-0 pb-2">
                    <span className="font-italic">Organisatie website / tickets →</span>
                  </Link>) : (<span></span>)
                }
              </div>
            </div>
          </div>
        )
      })}
      {limit && upcomingEventsList.length < upcomingEvents.length ?
        (
          <p className="mt-5 mb-0">
            <Link to="/agenda" className="btn btn-primary btn-xl mt-3 mb-5 text-uppercase">
              Meer evenementen
            </Link>
            <Link to="/repetities" className="btn btn-primary btn-xl mt-3 mb-5 ml-3 text-uppercase">
              Repetitieplanning
            </Link>
          </p>
        ) : null
      }
    </div>
  );

  function getVerenigingsonderdeelLabel(unit: string): string {
    switch (unit) {
      case null: return 'Verenigingsbreed'; break;
      case 'Jubal': return 'Jubal drum & bugle corps'; break;
      case 'Jong Jubal': return 'Jong Jubal drum & bugle corps'; break;
      default: return unit;
    }
  }
}

export default EventsList;